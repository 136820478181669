.wrapReview {
  display: flex;
  gap: 8px;
}
.reviewImg {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border: solid thin #aaa;
  border-radius: 6px;
  margin-top: 16px;
}
.reviewImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.infoReview {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12pt;
}
.iconDelete {
  width: 24px;
  height: 24px;
  color: red;
  cursor: pointer;
}
.dropzone {
  border: solid thin #aaa;
  text-align: center;
  padding: 40px 0;
  font-size: 12pt;
  font-weight: 700;
  border-radius: 6px;
}
.label {
  margin: 0 0 5px;
  font-weight: 400;
}
