.inputFile {
  height: 0;
  overflow: hidden;
  width: 0;
}
.inputFile + label {
  background: #fff;
  border: dashed 2px #b9b6b6;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  color: #ff7a00;
  cursor: pointer;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 14pt;
  font-weight: 400;
  outline: none !important;
  padding: 50px 0;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}
.inputFile + label:hover {
  background-color: #fffefe;
}
.wrapImg {
  width: 250px;
  max-height: 250px;
  overflow: hidden;
  position: relative;
}
.wrapImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btnDeleteImage {
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
  z-index: 99 !important;
  border: solid thin #fff !important;
}
.iconDeleteImg {
  color: #fff;
}
.field {
  width: 100%;
  padding: 18px 14px;
  border: solid thin #aaa;
  border-radius: 6px;
  font-size: 11pt;
}
.wrapListSuggestion {
  position: absolute;
  top: 40px;
  left: 0px;
  background: #fff;
  width: 100%;
  z-index: 9999999;
  border: solid thin #aaa;
  padding: 8px 16px;
  border-radius: 6px;
}
.liLabelSuggestion {
  list-style: none;
  font-style: italic;
  border-bottom: solid thin #aaa;
  padding-bottom: 4px;
  margin-bottom: 8px;
}
.listSuggestion {
  list-style: none;
  cursor: pointer;
  margin: 6px 0;
  transition: all;
  transition-duration: 300px;
}
.listSuggestion:hover {
  background: #fffefe;
}
